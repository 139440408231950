<template>
  <v-card class="rscope" dark>
    <v-card-title class="pl-4 mb-2"
      ><v-btn @click="handleBack()" small icon class="pl-0 mr-2"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <span class="fp settings-header" style="font-size: 15px"
        >REGISZTRÁCIÓ</span
      >
    </v-card-title>
    <v-card-text v-show="step === 1">
      <v-form v-model="isStep1Valid">
        <v-text-field
          label="Email"
          v-model="model.email"
          required
          :rules="[rules.required, rules.email]"
        ></v-text-field>
        <!--  -->
        <v-text-field
          v-model="model.password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          label="Jelszó"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>

        <!--  -->
        <v-text-field
          v-model="confirmPassword"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show2 ? 'text' : 'password'"
          label="Jelszó Ismét"
          counter
          @click:append="show2 = !show2"
        ></v-text-field>
        <!--  -->
        <v-text-field
          label="Boltkód"
          v-model="model.storeCode"
          required
          :rules="[rules.required]"
        ></v-text-field>
        <p class="error" v-if="!isStoreCodeValid">
          Nincs ilyen Boltkód a rendszerben
        </p>
      </v-form>
      <small v-if="model.password !== confirmPassword"
        >A jelszavak nem egyformák</small
      >
      <v-spacer></v-spacer>
    </v-card-text>
    <!-- STEP 2 -->

    <v-card-text v-show="step === 2">
      <v-form v-model="isStep2Valid">
        <h4>Személyes Adatok</h4>
        <v-text-field
          :label="$t('store.deliveryFirstname')"
          v-model="model.firstName"
          required
          :rules="[rules.required]"
        ></v-text-field>
        <!--  -->
        <v-text-field
          :label="$t('store.deliveryLastname')"
          v-model="model.lastName"
          required
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          :label="$t('agent.phone')"
          v-model="model.telephoneNumber"
          required
          type="number"
          :rules="[rules.required]"
        ></v-text-field>
        <h4>Céges Adatok</h4>
        <v-text-field
          label="Cégnév"
          v-model="model.store.firmName"
          required
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Székhely"
          v-model="model.store.firmLocation"
          required
          :rules="[rules.required]"
        ></v-text-field>
        <!--  -->
        <v-text-field
          label="Adószám"
          v-model="model.store.firmTaxNumber"
          type="number"
          required
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
          label="Bolt Címe"
          v-model="model.store.firmAddress"
          required
          :rules="[rules.required]"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-text v-if="step === 3">
      <h4>Jogosultság</h4>
      <v-radio-group v-model="model.role">
        <v-radio
          v-for="n in rolesAvailable"
          :key="n"
          :label="$t(`REG_${n}`)"
          :value="n"
        ></v-radio>
      </v-radio-group>
      <h4>Szerződések elfogadása</h4>
      <v-checkbox
        v-model="isPolicyAccepted"
        label="Elfogadom az ÁSZF-et és az Adatvédelmi nyilatkozatot"
      ></v-checkbox>
      <v-checkbox v-model="isAdult" label="Elmúltam 18 éves"></v-checkbox>
    </v-card-text>
    <div class="counter">
      <div class="d-flex justify-center align-center">
        <div class="dot" :class="{ active: step === 1 }"></div>
        <div class="dot" :class="{ active: step === 2 }"></div>
        <div class="dot" :class="{ active: step === 3 }"></div>
      </div>
    </div>
    <v-card-actions class="d-flex justify-center">
      <v-btn
        :disabled="!isStep1Valid"
        v-if="step === 1"
        class="fp"
        outlined
        rounded
        @click="next()"
        >TOVÁBB</v-btn
      >
      <v-btn
        :disabled="!isStep2Valid"
        v-if="step === 2"
        class="fp"
        outlined
        rounded
        @click="next()"
        >TOVÁBB</v-btn
      >
      <v-btn
        :disabled="!finalStep"
        v-if="step === 3"
        class="fp"
        outlined
        rounded
        @click="submit()"
        >REGISZTRÁLOK</v-btn
      >
    </v-card-actions>
    <v-spacer></v-spacer>
  </v-card>
</template>

<script>
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
import { fv } from "../util/functions.js";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE, DEFAULT_HEADER } from "../util/constants";

export default {
  props: ["valid", "isAddress"],
  data() {
    return {
      show1: false,
      show2: false,
      step: 1,
      rules: {
        required: (v) => !!v || "Kötelező.",
        min: (v) => v.length >= 8 || "Min 8 karakter",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
          "Érvénytelen email cím",
      },
      isStep1Valid: false,
      isStep2Valid: false,
      isStoreCodeValid: true,
      isPolicyAccepted: false,

      isAdult: false,
      confirmPassword: "",
      rolesAvailable: [],
      model: {
        email: "",
        password: "",
        storeCode: "",
        firstName: "",
        lastName: "",
        telephoneNumber: "",
        role: null,
        store: {
          firmName: "",
          firmLocation: "",
          firmTaxNumber: "",
          firmAddress: "",
        },
      },
    };
  },
  mounted() {},
  computed: {
    finalStep() {
      return this.isPolicyAccepted && this.isAdult && this.model.role;
    },
  },
  methods: {
    next() {
      if (this.step === 1) {
        this.storeInfo();
      } else if (this.step === 2) {
        this.step = 3;
      }
    },
    handleBack() {
      if (this.step > 1) {
        this.step--;
      } else {
        eventBus.$emit("CANCEL_REGISTRATION");
      }
    },
    finalShape() {
      return {
        email: this.model.email,
        firmAddress: this.model.store.firmAddress,
        firmLocation: this.model.store.firmLocation,
        firmName: this.model.store.firmName,
        firmTaxNumber: this.model.store.firmTaxNumber,
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        password: this.model.password,
        role: this.model.role,
        storeCode: this.model.storeCode,
        telephoneNumber: this.model.telephoneNumber,
      };
    },
    async submit() {
      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "POST",
        headers: DEFAULT_HEADER,
        body: JSON.stringify(this.finalShape()),
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}client/users/auth/register`,
          requestOptions
        );

        if (response.ok) {
          eventBus.$emit("REG_SUCCESS");
        } else {
          eventBus.$emit("REG_ERROR", response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async storeInfo() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      eventBus.$emit("LOAD_ON");
      let response = await fetch(
        `${API_BASE}client/stores/${this.model.storeCode}/firm-info`,
        requestOptions
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        this.isStoreCodeValid = true;
        this.model.store = jsonResponse;
        this.getRoles();
      } else {
        this.isStoreCodeValid = false;
      }
    },
    async getRoles() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      let response = await fetch(
        `${API_BASE}client/stores/${this.model.storeCode}/allowed-client-roles`,
        requestOptions
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        this.rolesAvailable = jsonResponse;
        this.step = 2;
      }
      eventBus.$emit("LOAD_OFF");
    },
  },
};
</script>
<style scoped>
h4 {
  /*color: var(--red);*/
  /*margin-bottom: 1rem;*/
  text-align: center;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #999;
  margin: 5px;
}
.dot.active {
  background-color: #fff;
  width: 20px;
  height: 20px;
}
</style>
