<template>
  <Login></Login>
</template>
<script>
import Login from "../components/Login.vue";

export default {
  components: {
    Login,
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     // const isValid = vm.$store.getters.isUserStillValid;
  //     console.log(vm);
  //     const isValid = false;
  //     if (!isValid) {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   });
  // },
};
</script>
