<template>
  <div class="comp-login">
    <div class="bg"></div>
    <v-dialog v-model="splash" fullscreen>
      <v-card class="splash" dark color="transparent">
        <figure>
          <v-img
            style="display: block"
            src="../assets/svg/hell_logo.svg"
          ></v-img>
        </figure>
        <svg
          width="86"
          height="254"
          viewBox="0 0 86 254"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style=""
        >
          <path
            d="M84 211L43 252L2 211"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M43 252L43 2"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="4 4"
          />
        </svg>
        <v-btn
          rounded
          outlined
          x-large
          dark
          class="mt-8 fp"
          @click="splash = false"
          >BEJELENTKEZÉS</v-btn
        >
      </v-card>
    </v-dialog>
    <v-dialog fullscreen v-model="isRecovery">
      <v-card v-if="isRecovery" dark style="text-align: center">
        <v-card-title class="pl-3 fp"
          ><v-btn class="" @click="isRecovery = false" icon
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <span style="font-size: 15px">Elfelejtett Jelszó</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="isRecoveryValid" ref="recovery">
            <v-text-field
              :label="$t('form.email')"
              placeholder="*****@***.***"
              v-model="recoveryEmail"
              required
              type="email"
              :rules="[rules.required, rules.email]"
              dark
              autofocus
            ></v-text-field>
          </v-form>
          <p>
            {{ $t("message.emailRecovery") }}
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="ml-auto mr-auto">
            <v-btn
              class="fp pl-8 pr-8"
              x-large
              @click="submitRecovery()"
              rounded
              outlined
              color="#fff"
              :disabled="!isRecoveryValid || recoveryEmail.length < 4"
            >
              KÜLDÉS
              <v-icon class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
        <p class="error" v-if="isNoEmail">{{ $t("error.noEmailInSystem") }}</p>
      </v-card>
    </v-dialog>
    <figure>
      <v-img style="display: block" src="../assets/svg/hell_logo.svg"></v-img>
    </figure>

    <v-card elevation="0" class="pa-4 transparent">
      <v-card-text>
        <!--      <p class="w">{{ $t("form.loginIntro") }}</p> -->
        <v-form v-model="isLoginValid">
          <v-text-field
            :label="$t('form.email')"
            placeholder="*****@***.***"
            v-model="user.email"
            dark
            required
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <v-text-field
            label="Jelszó"
            placeholder="******"
            type="password"
            v-model="user.password"
            required
            :rules="[rules.required]"
            @keydown.enter="attemptAuth()"
            dark
          ></v-text-field>
        </v-form>
        <p @click="isRecovery = true" class="w">
          {{ $t("form.passwordForgottenAction") }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="fp pl-8 pr-8"
          x-large
          @click="attemptAuth()"
          rounded
          outlined
          color="#fff"
          :disabled="!isLoginValid"
        >
          {{ $t("form.login") }}
          <v-icon class="ml-2">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
      <p class="reg-text mt-4 mb-4 text-center">
        Még nincs fiókod?
        <a href="#" @click.prevent="handleRegistration()"
          ><b>Regisztrálj!</b></a
        >
      </p>
    </v-card>
    <v-dialog fullscreen v-model="isRegistration">
      <registration v-if="isRegistration"></registration>
    </v-dialog>
    <v-dialog v-model="isRegSuccess" fullscreen>
      <v-card dark>
        <v-card-text class="order-message text-center">
          <v-sheet class="pa-8" color="#333">
            <v-avatar size="100" color="#000" class="mb-8"
              ><v-icon x-large>mdi-check</v-icon></v-avatar
            >
            <h2>Köszönjük!</h2>
            <p>
              Köszönjük, regisztrációdat rögzítettük. A jóváhagyásról email-ben
              értesítünk. Kérlek nézz vissza később!
            </p>
            <v-btn
              @click="isRegSuccess = false"
              rounded
              outlined
              x-large
              dark
              class="fp"
              ><v-icon>mdi-arrow-left</v-icon>VISSZA</v-btn
            >
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog fullscreen v-model="pwChange">
      <v-card dark>
        <v-card-text>
          <v-card-title>Adja meg az új jelszavát.</v-card-title>
          <v-form v-model="pwFormValid">
            <v-text-field
              v-model="newpw.primary"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              label="Jelszó"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
            <!--  -->
            <v-text-field
              v-model="newpw.control"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show2 ? 'text' : 'password'"
              label="Megerősítés"
              counter
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-form>
          <v-btn
            :disabled="!isPwValid"
            @click="finalPwStep()"
            rounded
            outlined
            x-large
            dark
            class="fp"
            >MEHET</v-btn
          >
          <v-btn
            @click="waitAndRedirect()"
            rounded
            outlined
            x-large
            dark
            class="fp"
            >MÉGSEM</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE, DEFAULT_HEADER } from "../util/constants";
import { fv } from "../util/functions.js";
import Registration from "../components/Registration.vue";
import firebase from "firebase/app";
import "firebase/app";
import "firebase/messaging";

export default {
  components: {
    Registration,
  },
  data() {
    return {
      show1: false,
      show2: false,
      showDialog: true,
      isLocked: false,
      splash: true,
      isRegistration: false,
      isRecovery: false,
      isLoginValid: false,
      isNoEmail: false,
      isRegSuccess: false,
      pwFormValid: false,
      pwToken: "",
      wasTokenized: false,
      newpw: {
        primary: "",
        control: "",
      },
      rules: {
        required: (v) => !!v || "Kötelező.",
        min: (v) => v.length >= 8 || "Min 8 karakter",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
          "Nem érvényes",
      },
      isRecoveryValid: false,
      user: {
        email: "",
        password: "",
      },
      recoveryEmail: "",
      pwChange: false,
    };
  },
  created() {
    eventBus.$on("REG_SUCCESS", () => {
      this.handleRegSuccess();
    });
    eventBus.$on("CANCEL_REGISTRATION", () => {
      this.isRegistration = false;
    });
    eventBus.$on("REG_ERROR", (response) => {
      this.isRegistration = false;
      messageBus.$emit("onNewMessage", {
        type: "error",
        label: this.$t("message.error"),
      });
    });
    if (this.$route.query.token) {
      this.splash = false;
      this.pwChange = true;
      this.pwToken = this.$route.query.token;
      // this.recoveryAttempt();
    }
  },
  mounted() {
    // console.log(this.$jwt.decode());
    if (this.$store.getters.isTokenValid) {
      this.$router.push("/");
    }
  },
  computed: {
    isValid() {
      // TO-DO: Validate locally
      return this.user.email !== "" && this.user.password !== "";
    },
    isPwValid() {
      return this.newpw.primary === this.newpw.control && this.pwFormValid;
    },
  },
  methods: {
    async finalPwStep() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        password: this.newpw.primary,
        token: this.pwToken,
      });
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      let res = await fetch(
        `${API_BASE}client/users/reset-password`,
        requestOptions
      );

      if (res.ok) {
        this.pwChange = false;
        this.waitAndRedirect();
        messageBus.$emit("onNewMessage", {
          type: "success",
          label: this.$t("message.success"),
        });
      } else {
        messageBus.$emit("onNewMessage", {
          type: "error",
          label: "Sikertelen. Próbálja újra később",
        });
        // this.waitAndRedirect();
      }
    },
    recoveryAttempt() {},
    handleRegSuccess() {
      this.isRegistration = false;
      this.isRegSuccess = true;
    },
    handleRegistration() {
      this.isRegistration = true;
    },
    async submitRecovery() {
      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Redirect-Uri": `${window.location.origin}/#/login`,
        },
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}client/users/forgotten-password?email=${this.recoveryEmail}`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.text();
          this.recoveryEmail = "";
          this.isRecovery = false;
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
        } else {
          this.isNoEmail = true;
          setTimeout(() => {
            this.isNoEmail = true;
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    attemptAuth() {
      if (!this.isLocked && this.isValid) {
        eventBus.$emit("LOAD_ON");
        this.$store.dispatch("logIn", this.user).then((res) => {
          if (res) {
            // console.log(res);
            messageBus.$emit("onNewMessage", {
              type: "success",
              label: this.$t("message.loginSuccess"),
            });

            this.getStore();
          } else {
            console.log(res);
            messageBus.$emit("onNewMessage", {
              type: "error",
              label: this.$t("message.loginFailed"),
            });
          }
          eventBus.$emit("LOAD_OFF");
        });
      }
    },
    getStore() {
      this.$store.dispatch("getStore").then((event) => {
        this.pushSetup();
      });
    },
    async updateUserPushToken(token) {
      let myHeaders = getLoggedInHeader(localStorage.getItem("token"));
      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        redirect: "follow",
      };

      let res = await fetch(
        `${API_BASE}client/users/push-token?pushToken=${token}`,
        requestOptions
      );

      if (res.ok) {
        this.waitAndRedirect();
      } else {
        messageBus.$emit("onNewMessage", {
          type: "error",
          label: this.$t("message.error"),
        });
      }
    },
    waitAndRedirect() {
      setTimeout(() => {
        window.location = window.location.href.split("?")[0];
        window.location.reload();
      }, 200);
    },
    pushSetup() {
      var config = {
        apiKey: "AIzaSyCysgLrYgewjvwonrspr2xP0e3hd6pFPMA",
        authDomain: "hell-energy-5eb96.firebaseapp.com",
        databaseURL: "https://hell-energy-5eb96.firebaseio.com",
        projectId: "hell-energy-5eb96",
        storageBucket: "hell-energy-5eb96.appspot.com",
        appId: "1:494277965836:web:9c7441903bebad559a1629",
        messagingSenderId: "494277965836",
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(config);
      } else {
        console.log("fb app was already there");
        firebase.app(); // if already initialized, use that one
      }
      if (!firebase.messaging.isSupported()) {
        this.waitAndRedirect();
        return;
      } else {
        console.log("push api supported");
      }
      const messaging = firebase.messaging();
      if (!this.wasTokenized) {
        messaging.usePublicVapidKey(
          "BIMy_e-lKQIH3a0q8smXSZXvl5SmV-F0ulEFR3VJYRb8l02NHTyKp2YiOlC8xFhYmiFv7EDij857jXoiJofLfMI"
        );
      }
      this.wasTokenized = true;
      messaging
        .requestPermission()
        .then(() => {
          console.log("Notification permission granted.");
          messaging.getToken().then((token) => {
            console.log("New token created: ", token);
            localStorage.setItem("pushToken", token);
            this.updateUserPushToken(token);
          });
        })
        .catch((err) => {
          console.log("Unable to get permission to notify.", err);
          this.waitAndRedirect();
        });
      messaging.onTokenRefresh(function () {
        messaging
          .getToken()
          .then(function (newToken) {
            console.log("Token refreshed: ", newToken);
            this.updateUserPushToken(newToken);
          })
          .catch(function (err) {
            console.log("Unable to retrieve refreshed token ", err);
            this.waitAndRedirect();
          });
      });
    },
  },
};
</script>

<style scoped>
.reg-text,
.reg-text a {
  color: #fff;
}

.w {
  color: #fff;
}
.title h2 {
  text-transform: uppercase;
  font-weight: 300;
}
.comp-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 200px);
  width: 100%;
}
.bg {
  background-image: url("../assets/app_login.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
}
.splash {
  background-image: url("../assets/app_splash.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.order-message .v-sheet {
  border-bottom: 3px solid var(--red) !important;
}
</style>
